.animated {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.cover-main-bj {
  width: 100%;
  height: 100%;
  opacity: 1;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(12px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 98;
}

.cover-main-openInSafari {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  margin: 20px auto;
  width: 80%;
  z-index: 99;
}
.am-notice-bar {
  background: none;

  svg {
    color: white;
  }
}

.attemptmask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

  .dialog_ {
    z-index: 10000;
    width: 78%;
    background: #fff;
    border-radius: 10px;
    -webkit-flex-direction: row;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center !important;
    position: relative;
    text-align: -webkit-center;
  }



  .dialog {
    z-index: 10000;
    width: 250px;
    height: 280px;
    background: #fff;
    border-radius: 10px;
    -webkit-flex-direction: row;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center !important;
    position: relative;
    text-align: -webkit-center;

    .ex {
      .icon_img {
        width: 66px;
        border-radius: 16px;
        border: 1px solid #dddddd;
        margin-bottom: 0;
        margin-top: 36px;
      }

      .p1 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 16px;
        margin-top: 18px;
      }

      .p2 {
        font-size: 12px;
        color: #999999;
        margin-bottom: 8px;
      }

      .btn {
        margin-top: 34px;
        width: 170px;
        padding: 12px 0;
        background: linear-gradient(-90deg, #f3e660, #ffdb26);
        box-shadow: 0px 4px 8px 0px rgba(245, 228, 85, 0.34);
        color: #664808;
        border-radius: 44px;
        text-align: center;
        font-size: 15px;
        font-weight: bold;
        border: 0;
        outline: none;
      }
    }

    .close {
      position: absolute;
      right: 20px;
      top: 0px;
      width: 14px;
      height: 14px;
      z-index: 1000001;
    }

    .content {
      position: absolute;
      width: 100%;
      left: 50%;
      top: 70%;
      transform: translate(-50%, -50%);

      .topTitle {
        font-size: 15px;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 16px;
      }

      .s-top {
        margin-top: 30px;
        font-size: 12px;
        color: #999999;
        margin-bottom: 8px;
      }

      .mid {
        font-size: 12px;
        color: #999999;
        margin-bottom: 8px;
      }

      .btn {
        margin-top: 19px;
        width: 140px;
        height: 35px;
        background: -webkit-linear-gradient(42deg, #ffb619, #ffcf58);
        background: linear-gradient(48deg, #ffb619, #ffcf58);
        box-shadow: 0 6px 12px 0 rgba(255, 213, 15, .23);
        border-radius: 44px;
        text-align: center;
        line-height: 32px;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        border: 0;
        outline: none;
      }
    }

    img {
      margin-top: 20px;
      margin-bottom: 15px;
    }

    .default {
      margin-top: 20px;
      font-size: 13px;
      color: #999999;
      font-weight: 600;

      span {
        font-size: 13px;
        color: rgba(255, 182, 25, 1);
      }
    }

    .btn_default {
      margin-top: 15px;
      width: 162px;
      padding: 13px 0;
      background: #bb890a;
      border-radius: 44px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
    }

    .btn-open {
      margin-top: 18px;
      width: 162px;
      padding: 13px 0;
      background: transparent;
      box-sizing: border-box;
      background: linear-gradient(-90deg, #f3e660, #ffdb26);
      box-shadow: 0px 4px 8px 0px rgba(245, 228, 85, 0.34);
      color: #664808;

      border-radius: 44px;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
    }

    .install_key_title {
      font-size: 16px;
      text-align: center;
      border-bottom: 1px solid #f1f1f1;
      padding-bottom: 16px;

      color: #333333;
      font-weight: bolder;
    }

    .install_step {
      margin-top: 10px;
      margin-bottom: 29px;
      text-align: left;
      padding-left: 4px;


      p {
        color: #333333;
        // margin-left: 30px;
        font-size: 14px;
        margin-top: 10px;
        padding-bottom: 0;
        display: inline-block;
      }

      a {
        color: #ffb619;
        margin-left: 32px;
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 15px;
        line-height: 26px;
      }

      div span {
        display: inline-block;
        width: 21px;
        height: 21px;
        line-height: 22.4px;
        flex-shrink: 0;
        background: linear-gradient(-90deg, #f3e660, #ffdb26);
        box-shadow: 0px 4px 8px 0px rgba(245, 228, 85, 0.34);
        color: #664808;
        font-size: 10px;
        margin-right: 11px;
        border-radius: 50%;
        text-align: center;
      }

      .intro {
        color: #999999;
        margin-left: 32px;
        font-size: 13px;
        margin-bottom: 15px;
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  background: #F6F6F6;

  .gptnotice {
    //  width: 7.13rem;
    padding: 0 10px;
    margin-top: 34px;
    position: relative;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
  }

  .head {
    height: 4.82rem;
    background-image: url('../../assets/v3/bj.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;

    .head_itemBox {
      width: 7.14rem;
      background: #FFFFFF;
      box-shadow: 1px 6px 16px 0px rgba(255, 152, 2, 0.04);
      border-radius: 10px;
      align-self: flex-end;
      margin: -16px auto;
      padding: 18px 0;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .head_itemBox_item {
        text-align: center;

        .hongbao {
          animation-duration: 2s;
          animation-iteration-count: 3;
          animation-delay: 2s;
        }

        img {
          z-index: 1 !important;
          height: 38px;
        }

        p {
          font-size: 0.24rem;
          font-weight: bold;
          color: #1d1d1f;
          margin-top: 8px;
        }
      }
    }
  }

  .main {
    width: 7.13rem;
    background: #FFFFFF;
    box-shadow: 1px 5px 21px 0px rgba(0, 0, 0, 0.03);
    border-radius: 9px;
    margin: 32px auto;
    box-sizing: border-box;
    padding: 18px 18px;

    >img {
      width: 3.41rem;
    }

    .userList {
      margin-top: 9px;

      .v-item {
        >span {
          padding: 4px 8px 4px 5px;
          display: flex;
          align-items: center;

          >img {
            border-radius: 50%;
            vertical-align: sub;
            margin-right: 5px;
          }

          background: rgba(255, 255, 255, .15);
          border-radius: 22px;
          font-size: 12px;
          font-weight: bold;
          color: #866846;
        }
      }
    }
  }

  .footer {

    width: 100%;
    z-index: 10000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    padding: 12px 28px;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-backdrop-filter: saturate(180%) blur(4px);
    backdrop-filter: saturate(180%) blur(4px);
    position: fixed;
    bottom: 0px;
    background-color: rgba(255, 255, 255, .66);
    border-top: 1px solid #f3f3f3;


    .left {
      display: flex;
      align-items: center;
    }


    img:first-child {

      width: 54px;
      height: 54px;
      border-radius: 14px;
    }

    .footer_text {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-left: 14px;

      p:first-child {
        font-size: 16px;
        font-weight: bold;
        color: #343434;

        >span {
          color: #FB4D41;
        }
      }

      p:last-child {
        margin-top: 6px;
        font-size: 12px;
        color: #666666;
      }
    }

    img:last-child {
      width: 1.73rem;
      height: .73rem;
      animation-duration: 1.2s;
      animation-iteration-count: 3;
    }
  }

  .mask {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;

    ._dialog {
      width: 250px;
      height: 336px;
      background: rgba(255, 255, 255, 1);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 325px;
      }
    }

    .dialog {
      width: 250px;
      height: 336px;
      background: rgba(255, 255, 255, 1);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .dialog-img {
        width: 187px;
        margin: 12px 0;
      }

      .new {
        width: 122px;
        height: 34px;
        background: linear-gradient(48deg, rgba(255, 182, 25, 1), rgba(255, 207, 88, 1));
        box-shadow: 0 6px 12px 0 rgba(255, 213, 15, 0.23);
        border-radius: 18px;
        text-align: center;
        line-height: 34px;
        color: white;
        font-weight: 600;
        font-size: 15px;
      }

      .old {
        width: 122px;
        height: 34px;
        background: white;
        border-radius: 18px;
        font-weight: 600;
        text-align: center;
        line-height: 34px;
        color: #FFBA27;
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 4px;
      }
    }
  }

  .title {
    font-weight: bold;
    font-size: 16px;
    margin-right: 12px;
  }

  .spe {
    margin-bottom: 15px;
  }

  .coin {
    height: 22px;
    background: rgba(246, 246, 246, 1);
    border-radius: 20px;
    position: relative;
    text-align: center;
    line-height: 22px;
    font-size: 12px;
    color: #333;
    padding-left: 26px;
    padding-right: 12px;

    img {
      position: absolute;
      left: 4px;
      top: 2px;
      width: 22px;
      height: 19px;
    }
  }

  .propertyInfo {
    display: flex;
    flex-direction: column;
    padding-top: 22px;

    .assert {
      display: inline-block;
      font-size: 12px;
      color: #999999;

      img {
        width: 4px;
        height: 8px;
      }
    }

    .wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;
    }

    .amount {
      font-weight: 500;
      font-size: 1.8rem;
      font-family: 'MiSans-Demibold';
      margin-bottom: 18px;
    }

    .img-float {
      width: 24%;
      position: absolute;
      right: 0;
      top: 7%;
    }
  }

  .activityInfo {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;

    .img {
      width: 100%;
      height: 184 * 100vw / 750;
    }

    .circle {
      width: 30px;
      padding: 0 10px;

      .icon {
        font-size: 16px;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}