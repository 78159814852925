.itemContainer {
  display: flex;
  flex-direction: column;
  .done {
    opacity: 0.4;
  }
  .itemWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 14px 0;
    .right {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: stretch;
      align-items: center;
      .img {
        border-radius: 12px;
        border: 1px solid #f3f3f3;
      }
      .type {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        margin-left: 15px;
        .name_status {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .font {
            font-size: 15px;
            font-weight: bold;
            color: #333333;
          }
          .status {
            padding: 5px 13px;
            border-radius: 15px;
            background: rgba(255, 118, 121, 0.1);
            margin-left: 8px;
            color: #FF7679;
            font-size: 12px;
          }
        }
        .small {
          font-size: 12px;
          color: #999999;
          margin-top: 8px;
        }
        .small-process{
          font-size: 12px;
          //line-height: 32px;
          color: #FF7679;
          font-weight: bold;
          margin-bottom: 5px;
        }
      }
    }
    .touch {
      font-size: 22px;
      font-family: 'MiSans-Demibold';
      color: #FF7679;
    }
    .touch_ {
      font-size: 12px;
      font-family: 'MiSans-Demibold';
      color: #FF7679;
      margin-bottom: 5px;
    }
  }
}