#TF {
    background: #E7EBF0;
    padding: 30px 23px 30px;
    min-height: 100vh;

    header {
        text-align: center;
        position: relative;
        width: 100%;
        margin-bottom: 15px;

        span {
            line-height: 22px;
            font-weight: bold;
            font-size: 16px;
            color: #1D1D1F;
        }

        img {
            position: absolute;
            left: 8px;
        }
    }

    >img {
        width: 100%;
    }

    .tfbj {
        background-image: url('../../assets/v3/Frame 207.png');
        background-size: 100% auto;
        width: 100%;
        height: 250px;
        position: relative;
        background-repeat: no-repeat;

        .box1 {
            position: absolute;
            top: 1.70rem;
            left: 22%;
            display: flex;
            gap: 12px;
            align-items: center;

            img {
                width: 48px;
                height: 48px;
                border-radius: 10px;
            }

            .box2 {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 5px;
            }

            span {
                font-size: 14px;
                font-weight: bold;
                color: #2A2A2A;
                margin-top: 3px;
            }
        }
    }
}