.loginWrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  .back {
    position: absolute;
    left: 4%;
    top: 2%;
    height: 20px;
    width: 20px;
  }
  .content-wrapper{
    background: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    .title{
      font-size: 22px;
      color: #333333;
      font-weight: 500;
      margin: 0px 0 35px 0;
    }
    .input{
      width: 100%;
      height: 60px;
      font-size: 14px;
    }
    .box{
      width: 90%;
      position: relative;
      border-bottom: 1.5px solid #f3f3f3;
      .close{
        position: absolute;
        right: 2%;
        top:21px
      }
    }
    .codebox{
      width: 90%;
      position: relative;
      border-bottom: 1.5px solid #f3f3f3;
      .getCode{
        position: absolute;
        right: 2%;
        top:21px;
        font-size: 14px;
        color: #FFC53E;
      }
    }
    .safebox{
      width: 90%;
      position: relative;
      .close{
        position: absolute;
        right: 2%;
        top:21px
      }
    }
    .btn{
      width: 90%;
      height:44px;
      background:linear-gradient(48deg,rgba(255,182,25,1),rgba(255,207,88,1));
      box-shadow:0px 6px 12px 0px rgba(255,213,15,0.23);
      opacity:0.94;
      border-radius:22px;
      text-align: center;
      line-height: 44px;
      color: white;
      margin-top: 27px;
      font-size: 14px;
    }
    .tip{
      font-size: 14px;
      color: black;
      margin-bottom: 14px;
      span{
        color: #f69080;
      }
    }
    .alert{
      width: 67px;
      margin-bottom: 32px;
    }
  }
}
.rules{
  font-size: 12px;
  color: #999;
  position: fixed;
  bottom: 22px;
  line-height: 22px;
  text-align: center;
  a{
    color: #ffc53e;
  }
}