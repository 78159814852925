.animated {
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.attemptWrapper {
  display: flex;
  flex-direction: column;
  // position: relative;
  background: white;
  margin-top: 16px;
  .attemptmask{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: rgba(0,0,0,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    .dialog_ {
      z-index: 10000;
      width: 78%;
      background: #fff;
      border-radius: 10px;
      -webkit-flex-direction: row;
      flex-direction: column;
      -webkit-justify-content: center;
      justify-content: center !important;
      position: relative;
      text-align: -webkit-center;
    }
    .dialog {
      z-index: 10000;
      width: 250px;
      background: #fff;
      border-radius: 10px;
      -webkit-flex-direction: row;
      flex-direction: column;
      -webkit-justify-content: center;
      justify-content: center !important;
      position: relative;
      text-align: -webkit-center;
      .ex{
        .p1{
          font-size: 15px;
          font-weight: 600;
          margin-bottom: 16px;
          margin-top: 15px;
        }
        .p2{
          font-size: 12px;
          color: #999999;
          margin-bottom: 8px;
        }
        .btn {
          margin-top: 30px;
          width: 140px;
          height: 35px;
          background: -webkit-linear-gradient(42deg,#ffb619,#ffcf58);
          background: linear-gradient(48deg,#ffb619,#ffcf58);
          box-shadow: 0 6px 12px 0 rgba(255,213,15,.23);
          border-radius: 44px;
          text-align: center;
          line-height: 32px;
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          border: 0;
          outline: none;
        }
      }
      .close {
        position: absolute;
        right: 20px;
        top: 0px;
        width: 14px;
        height: 14px;
        z-index: 1000001;
      }
      .content{
        position: absolute;
        width: 100%;
        left: 50%;
        top: 70%;
        transform: translate(-50%, -50%);
        .topTitle{
          font-size: 15px;
          font-weight: 600;
          margin-top: 10px;
          margin-bottom: 16px;
        }
        .s-top{
          margin-top: 30px;
          font-size: 12px;
          color: #999999;
          margin-bottom: 8px;
        }
        .mid{
          font-size: 12px;
          color: #999999;
          margin-bottom: 8px;
        }
      }
      .close {
        position: absolute;
        right: 20px;
        top: 0px;
        width: 14px;
        height: 14px;
        z-index: 1000001;
      }

      img {
        margin-top: 20px;
        margin-bottom: 15px;
      }

      .default {
        margin-top: 20px;
        font-size: 13px;
        color: #999999;
        font-weight: 600;

        span {
          font-size: 13px;
          color: rgba(255,182,25,1);
        }
      }
      .btn {
        margin-top: 19px;
        width: 140px;
        height: 35px;
        background: -webkit-linear-gradient(42deg,#ffb619,#ffcf58);
        background: linear-gradient(48deg,#ffb619,#ffcf58);
        box-shadow: 0 6px 12px 0 rgba(255,213,15,.23);
        border-radius: 44px;
        text-align: center;
        line-height: 32px;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        border: 0;
        outline: none;
      }
      .btn_default {
        margin-top: 15px;
        width: 162px;
        height: 42px;
        background: #bb890a;
        border-radius: 44px;
        text-align: center;
        line-height: 42px;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
      }

      .btn-open {
        margin-top: 15px;
        width: 162px;
        height: 42px;
        background: transparent;
        box-sizing: border-box;
        border: 2px solid #ffb619;
        box-shadow: 0 6px 12px 0 rgba(255,213,15,.23);
        border-radius: 44px;
        text-align: center;
        line-height:38px;
        color: #ffb619;
        font-size: 16px;
        font-weight: 600;
      }

      .install_key_title {
        font-size: 16px;
        text-align: center;
        border-bottom: 1px solid #f1f1f1;
        padding-bottom: 16px;

        color: #333333;
        font-weight: bolder;
      }

      .install_step {
        margin-top: 10px;
        margin-bottom: 29px;
        text-align: left;
        padding-left: 4px;


        p {
          color: #333333;
          // margin-left: 30px;
          font-size: 14px;
          margin-top: 10px;
          padding-bottom: 0;
          display: inline-block;
        }
        a{
          color: #ffb619;
          margin-left: 32px;
          font-size: 13px;
          font-weight: 600;
          margin-bottom: 15px;
          line-height: 26px;
        }
        div span {
          display: inline-block;
          width: 21px;
          height: 21px;
          line-height: 22.4px;
          flex-shrink: 0;
          background: #ffb619;
          color: #fff;
          font-size: 10px;
          margin-right: 11px;
          border-radius: 50%;
          text-align: center;
        }
        // div::after {
        //   content: '';
        //   width: 1px;
        //   height: 16px;
        //   background: #fe8aa6;
        //   position: absolute;
        //   bottom: -19px;
        //   left: 10px;
        // }
        .intro {
          color: #999999;
          margin-left: 32px;
          font-size: 13px;
          margin-bottom: 15px;
        }
      }
    }
  }
  .header{
    position: fixed;
    top: 0;
    width: 100%;
    height: 307 * 100vw / 750;
    font-size: 15px;
    color: #333;
    background-image: url("../../assets/v2/banner/attempt.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    z-index: 10;
    .nav{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      .back{
        padding-left: 18px;
        height: 20px;
        width: 20px;
        padding-top: 18px;
      }
      .feedback{
        margin-right: 18px;
        margin-top: 15px;
        height: 17px;
        border: 1px solid #666666;
        padding: 3px 11px;
        font-size: 12px;
        line-height: 17px;
        border-radius: 44px;
        font-weight: 600;
        // width: 16px;

      }
    }
    .intro{
      padding-left: 20px;
      margin-top: 30px;
      .title{
        font-size: 22px;
        font-weight: 500;
        color: #333;

      }
      .tiny{
        margin-top: 6px;
        font-size: 12px;
        color: #333;
      }
    }

  }
  .attemptContent{
    display: flex;
    flex-direction: column;
    background: white;
    .slide{
      height: 280 * 100vw / 750;
      border-radius: 10px;
      margin-bottom: 15px;
      margin-top: 16px;
      .img{
        width:100%;
        height: 100%;
      }
    }
    .title{
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 10px;
    }
    .normal{
      display: flex;
      flex-direction: column;
      .step{
        color: #999;
        font-size: 12px;
        margin-bottom: 19px;
      }
    }
  }

}
.app-info{
  background-image: url("../../assets/download/app.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 731 * 100vw / 750;
  position: relative;
  border-radius: 10px;
  img{
    position: absolute;
    left: 20%;
    top: 44%;
    width: 110 * 100vw / 750;
    height: 110 * 100vw / 750;
    border:1px solid #F6F6F6;
    border-radius: 14px;
  }
  .name{
    position: absolute;
    left: 43.5%;
    top: 44%;
    display: flex;
    font-size: 14px;
    font-weight: bold;
  }
}